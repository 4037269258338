<template>
    <div>
        <Header></Header>
        <div class="outer_box content_box">
            <el-page-header class="pg_header" 
                            @back="()=>{$router.push('/')}" 
                            :content="title">
            </el-page-header>
            <div class="play_box">
                <div class="left_movie">
                    <video-player v-if="isSelf || haveBuy" class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions">
                    </video-player>
                    <div class="no_play" v-else>
                        <i class="el-icon-s-goods"></i>
                        <p>{{$t('Need to subscribe to')}}</p>
                    </div>
                </div>
                <div class="right_movie">
                    <p class="right_movie_title">{{title}}</p>
                    <p class="right_movie_text">{{$t('Director')}}：{{director}}</p>
                    <p class="right_movie_text">{{$t('Starring')}}：{{actor}}</p>
                    <p class="right_movie_text">{{$t('Details')}}：{{description}}</p>
                    <el-button size="small" type="primary" plain class="block_btn" @click="clickAction(2)" v-if="isSelf">{{$t('Transaction settings')}}</el-button>
                    <el-button size="small" type="primary" plain class="block_btn" @click="clickAction(3)" v-if="!isSelf && !haveBuy">{{$t('Subscribed')}}</el-button>
                    <el-button size="small" type="primary" plain class="block_btn" @click="clickAction(4)" v-if="!isSelf">{{$t("Copyright")}}</el-button>
                </div>
            </div>
            <div class="sub_title">
                {{$t('Related recommendation')}}
            </div>
            <div class="movie">
                <ul class="movie_list">
                <li class="movie_list_item" v-for="(item,index) in movieList" :key=index @click="toDetail(item)">
                    <div class="movie_list_item_box">
                    <div class="img_box">
                        <img :src="$ipfs_url+item.title_page" alt="">
                    </div>
                    <div class="movie_name">
                        {{item.title}}
                    </div>
                    <div class="movie_desc ellipsis">
                        {{item.description}}
                    </div>
                    </div>
                </li>
                </ul>
            </div>
            <el-dialog
                :title="$t('Notice')"
                :visible.sync="qrModal"
                width="30%"
                :before-close="handleClose">
                <div class="qr_modal_code">
                    <span>{{modalTitle}}</span>
                    <div class="qr_code" ref="qrCodeUrl"></div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleClose">OK</el-button>
                </span>
            </el-dialog>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import { getTableRows } from '@/utils/RequestsRpc'
import { videoPlayer } from 'vue-video-player'
import QRCode from 'qrcodejs2'
export default {
  components:{ Header, Footer, videoPlayer },
  data(){
      return{
          account:"",
          accountId:"",
          id:"",
          title:"",
          director:"",
          actor:"",
          description:"",
          copyright_transfer_fee:"",
          read_fee:"",
          isSelf:false,
          haveBuy:false,
          showModal:false,
          qrModal:false,
          movieList:[],
          modalTitle:"請用NFT影視app掃碼進行操作",
          playerOptions : {
            playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
            autoplay : false, //如果true,浏览器准备好时开始回放。
            muted : false, // 默认情况下将会消除任何音频。
            loop : false, // 视频一结束就重新开始。
            preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language : 'zh-CN',
            aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources : [],
            poster : "", //你的封面地址
            notSupportedMessage : this.$t('This video is currently unavailable, Please try again later'), //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar : {
                timeDivider : true, //当前时间和持续时间的分隔符
                durationDisplay : true, //显示持续时间
                remainingTimeDisplay : false, //是否显示剩余时间功能
                fullscreenToggle : true  //全屏按钮
            }
          },
      }
  },
  methods:{
    init(){
           let local = window.localStorage.getItem("pcLocalAccount")
           if(local){
                let localJson = JSON.parse(local)
                this.account = localJson.nft_account
                this.accountId = localJson.account_id
           }
           this.id = this.$route.query.id
           // 獲取詳情
           this.$fetch(this.$center_url+"/v1/movice/detail?id="+this.id).then( async (res)=>{
               if(this.accountId === res.data.pub_account_id){
                   this.isSelf = true
               }
               this.getBuyId();
               let info = res.data;
               this.title = info.title
               this.director = info.director
               this.actor = info.actor
               this.description = info.description
               this.copyright_transfer_fee = info.copyright_transfer_fee
               this.read_fee = info.read_fee
               this.getRecomm(info.classification)
           })
    },
    // 獲取是否購買過
    getBuyId(){
        getTableRows({ code: 'nftio.nft', scope: this.account, table: 'caccreditt'}).then((res) => {
            if (res.rows.length > 0) {
                let vtok = res.rows[0].vtok;
                for (let i in vtok) {
                    if (vtok[i] === this.id) {
                        this.haveBuy = true;
                        this.checkHaveUrl()
                        break
                    }
                }
            }
        });
    },
    // 检测url
    async checkHaveUrl(){
        let movieKey = await this.getUriList();
        console.log(movieKey)
        if(!movieKey){
            this.clickAction(5)
            return
        }else{
            this.decryptUrl(movieKey)
        }
    },
    async getUriList(){
        let movieKey;
        let caccredinfot = await getTableRows({ code: 'nftio.nft', scope: this.account, table: 'caccredinfot' })
        let newArr = caccredinfot.rows
        for(var i in newArr){
            let item = newArr[i]
            let tokid = item.tokid
            if(tokid === this.id){
                let tempsecretta = await getTableRows({ code: 'nftio.nft', scope: this.account, table: 'tempsecretta' })
                if(tempsecretta.rows.length === 0){
                    movieKey = ""
                    break
                }else{
                    for(let i in tempsecretta.rows){
                        let item = tempsecretta.rows[i]
                        if(item.tokid === this.id){
                            movieKey = tempsecretta.rows[0].secret
                            break
                        }else{
                            movieKey = ""
                        }
                    }
                }
            }
        }
        console.log(movieKey)
        return movieKey
    },
    // 解密密码
    decryptUrl(movieKey){
        let sendData = {
            ciphertext:movieKey,
            tokid:this.id
        }
        this.$post(this.$center_url+"/v1/movice/url/decrypt",sendData).then((res)=>{
            let url = res.data;
            this.playerOptions.sources.push({
                type: "video/mp4",
                src : url
            })
        })
    },
    // 獲取推薦
    getRecomm(classify){
        this.$fetch(this.$center_url+`/v1/movice/list?state=15&page=1&num=8&classification=${classify}`).then((res)=>{
            this.movieList = res.data.data;
        })
    },
    createQR(type){
        let qrcodeText = {
            type:type,
            data:this.id
        }
        new QRCode(this.$refs.qrCodeUrl, {
            text: JSON.stringify(qrcodeText),
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
    handleClose(){
        this.$refs.qrCodeUrl.innerHTML = ''
        this.qrModal = false
    },
    clickAction(type){
        if(!this.account){
            this.$alert(this.$t('Need to log in to'), this.$t('Notice'),{confirmButtonText:this.$t('confirm')})
            return
        }
        this.qrModal = true;
        this.modalTitle = this.$t('needs to refresh the link with the APP')
        this.$nextTick(()=>{
            this.createQR(type)
        })
    }
  },
  created(){
      this.init()
  }
}
</script>
<style scoped lang="less">
@import url("./index.less");
@import url("./video-js.css");
</style>